.courseDescriptionDiv {
    display: -webkit-box!important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 5em;
}
.CLB_course_title {
    display: -webkit-box!important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 3em;
}
.CNIMG {
    width: 50px;
    height: 30px;
    object-fit: contain;
}
.cmc_linkcat .CLB_course_title_small {
    /* height: 30px; */
    font-weight: 500;
    color: #000;
}
.cmc_linkcat:hover .CLB_course_title_small {
    color: #7a3694;
}
.NavigationActive {
    font-weight: 700 !important;
    color: #7a3694 !important;
    border-bottom: 1px solid #7a3694;
}
.NVCatiLi span {
    transform: rotate(45deg);
    height: 20px;
    width: 20px;
    position: absolute;
    top: 40px;
    background: #fff;
    left: 50%;
}
.CLB_course_title_small {
    display: -webkit-box!important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: #333;
}
.CLB_course_description {
    display: -webkit-box!important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 5em;
}

.contentBox:hover .CLB_course_description  {
    display: -webkit-box!important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 7em;
}
.courseimgsj {
    height: 150px;
    width: 100%;
    object-fit: contain;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    background: #fff;
}
@media (max-width: 767px) {
    .courseimgsj {
        border: 1px solid #dedede;
        border-radius: 0px;
        background: #f5f5f5;
    }
}
.textCrsDes {
    display: -webkit-box!important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    /* font-weight: 400;
    font-size: 14px; */
}
.dot {
    height: 4px;
    width: 4px;
    margin-top: 12px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #e4a6fb;
    border-radius: 50%;
    display: inline-block;
}
.strike-text {
    color: red;
    text-decoration: line-through;
}

.ListCourse {
    border: 1px solid #dedede;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}
.ListCourse .contentBox {
    padding: 10px !important;
}
.ListCourse:hover .contentBox, .ListCourse:hover .imgBox {
    opacity: 1;
}
/* @media (min-width: 767px) {
    .ListCourse:hover .contentBox {
        opacity: 0;
    } 
} */
.ListCourse .backContent {
    background: #fff;
    right: 0px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    position: absolute;
    padding: 10px;
    -webkit-transition: all 3s ease-in-out;
    -moz-transition: all 3s ease-in-out;
    -ms-transition: all 3s ease-in-out;
    -o-transition: all 3s ease-in-out;
    transition: all 0.6s ease-in-out;
    opacity: 0;
    overflow: auto;
    color: #f5f5f5;
    background: #000000db;
} 
.ListCourse .backContent h6 {
    color: #fff;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
}
.ListCourse .backContent .textBox small {
    margin-bottom: 10px;
    display: block;
}
.ListCourse:hover .backContent {
    opacity: 1;
} 
.ListCourse .shopBox {
    display: flex;
    justify-content: space-around;
    /* justify-content: space-between;
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px; */
}
@media (max-width: 767px) {
    .ListCourse .shopBox {
        display: flex;
        position: absolute;
        bottom: 5px;
        left: 5px;
        right: 5px;
    }
    .ListCourse .shopBox .main-btn {
        font-size: 10px;
        padding: 0px 5px   
    }
}
.ListCourse:hover .main-btn {
    padding: 5px 20px;
    line-height: 15px;
}

.ListCourse .main-btn:hover {
    /* color: #fff;
    border: 1px solid #fff; */
    /* color: #b087c0;
    border: 1px solid #b087c0; */
    background-color: #7f13ab;
    border: 1px solid #7f13ab;
    color: #f9f9f9;
}
.HomeCourseSliderHere {
    margin-bottom: 15px;
}
.HomeCourseSliderHere .slick-dots {
    bottom: -5px;
}
.HomeCourseSliderHere .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 3px;
}
.HomeCourseSliderHere .slick-dots li.slick-active button {
    background: #7a3694;
}
.HomeCourseSliderHere .slick-dots li button {
    border: 1px solid #7a3694;
    border-radius: 50%;
    width: auto;
    height: auto;
    padding: 2px;
}
.HomeCourseSliderHere .slick-dots li button:before {
    line-height: 7px;
    height: 7px;
    width: 7px;
}
.HomeCourseCategorySlidersafsafa .slick-slide {
    width: max-content !important;
    padding: 0px 15px;
}
.courseIncludes {
    margin-bottom: 10px;
    font-weight: 500;
    color: #2f0043;
    font-size: 15px;
}
.courseIncludes img {
    height: 25px;
    margin-right: 9px;
}
.curpoint {
    cursor: pointer;
}