
.audioPlayercontainer {
    height: 470px;
    width: 55%;
    
  }
  .forflexing {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .forflexingAC {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
  }
  .audiocontentWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: 26px;
  }
  .blackBOX {
    height: 100%;
    background-color: black;
    padding: 5px;
    margin-left: -5px;
    position: relative;
  }
  .forActualContent {
    
    width: 100%;
    border-bottom: 1px solid #d3cbcb;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .boXDIv {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-left: -5px;
  }
  .underBlackBox {
    height: 100%;
    width: 100%;
  }
  
  .playingContainermp3 {
    margin: 15px;
    height: 93%;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #f5f5f500;
    background-image: linear-gradient(white, gray);
    border: 1px solid #d3cbcb;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  .audioPlayTrackCopy b {
    padding-top: 10px;
    display: block;
    padding-left: 10px;
    color: #7a3694;
  }
  .audioPlayTrackCopy {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 0px;
    background: #f5f3f3;
    z-index: 999999;
    padding: 0px;
    border-top: 0.5px solid #d3cbcb;
    border-radius: 15px;
    height: 11%;
  }
  .audioPlayTrackCopy {
    position: relative;
    
  }
  .audioPlayTrackCopy button.ry {
    position: absolute;
    cursor: pointer;
    left: 20px;
    top: 15px;
    background: #ffffff;
    border: 0px;
  }
  /* .text-infom {
    color: #55a6bb;
  } */
  .crossIcon {
    color: white;
    height: 20px;
    width: 20px;
    border-radius: 12px;
    background-color: black;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .audioHeaderDiv {
    display: flex;
    justify-content: space-between;
    padding: 11px;
    z-index: 10;
  
    width: 100%;
    position: absolute;
    align-items: center;
    color: antiquewhite;
    background: linear-gradient(180deg, rgb(0 0 0 / 37%) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 15px;
  }
  .rowLifeeat {
    padding: 15px;
  }
  
  .underNameCol11 {
    margin-top: 5px;
    font-weight: 400;
    line-height: normal;
    font-size: 13px;
  }
  .text-infomtech {
    font-size: 19px;
  }
  .underNameCol {
    font-weight: 400;
    font-size: 13px;
  }
  .PlayButtonSs {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
  }
  .PlayButtonSsLast {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .PlayButtonSsFirst1 {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .PlayButtonSsRR {
    position: absolute;
    display: flex;
    left: 32%;
    top: 37%;
    background-color: white;
    border-radius: 50px;
    height: 25px;
    width: 25px;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
  
  .photoInstructorr {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  .rowOfOne {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100px;
    justify-content: center;
  }
  .rowOfPLayer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
  .photoId01 {
    min-height: 20px;
    min-width: 20px;
    max-width: 80%;
    max-height: 87%;
    border-radius: 10px;
    object-fit: contain;
  }
  .photoId02 {
    min-height: 20px;
    min-width: 25px;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 15px;
  }
  
  .audioPlayerPhoto01 {
    
    
    
    height: 100%;
    width: 100%;
    
}
  
  
  .mainWrapper {
    padding: 5px;
    padding-left: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .indxMediaWrapper {
    width: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  
  
  .forMUltiMedia {
    position: relative;
    display: flex;
    gap: 20%;
    min-width: 114px; 
  }
  
  .forMainCOntentInfo {
    width: 60%;
  }
  
  .durationLikeWrapper {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  @media(max-width: 1220px){
    .audiocontentWrapper{
        flex-direction: column;
        width: 100%;
        
    }

    .audioPlayercontainer{
       
        width: 100%;
        
    }
    .forflexingAC{
        width: 100%;
    }
  }

  @media(min-width: 1220px){
  
    .audioPlayercontainer{
       
      position: sticky;
      top: 81px;
      
  }
  }
  
  @media( max-width : 550px){
    .forMUltiMedia{
      display: none;
    }
    .audioPlayercontainer{
      height: 300px;
    }
    .boXDIv{
      padding-bottom: 15px;
    }
   .forflexingAC{
    font-size: small;
   }
  }


  .rhap_progress-section .rhap_time {
    color: rgb(255, 255, 255);
    font-size: rgb(255, 255, 255);
    font-weight: 700;
  }
  
  .rhap_main-controls-button svg {
    color: #ffffff;
    width: 100%;
    height: 100%;
  }
  
  .rhap_button-clear.rhap_volume-button {
    color: #ffffff !important;
  }
  .rhap_volume-filled, .rhap_volume-indicator {
    background-color: #ffffff !important;
  }
  .rhap_button-clear{
    color: #ffffff;
  }