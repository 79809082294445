.listin5:nth-child(5n+1) .appie-features-content:hover .hoverContent {
    left: auto;
    right: 100%;
}    
.listin5:nth-child(5n+1) .appie-features-content .hoverContent:before {
    left: auto;
    right: -10px;
}
.listin5crsstarted:nth-child(5n) .appie-features-content:hover .hoverContent {
    left: auto;
    right: 100%;
}    
.listin5crsstarted:nth-child(5n) .appie-features-content .hoverContent:before {
    left: auto;
    right: -10px;
}
/* Most Popular University Accredited Courses */
.listin2:nth-child(2n+1) .appie-features-content:hover .hoverContent {
    left: auto;
    right: 100%;
}    
.listin2:nth-child(2n+1) .appie-features-content .hoverContent:before {
    left: auto;
    right: -10px;
}
/* Most Popular University Accredited Courses */

@media (min-width: 768px) {
    .listin5, .listin5crsstarted {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .listin2 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .listin3 {
        flex: 0 0 33%;
        max-width: 33%;
    }
    .category_sort_filter_right {
        float: left;
    }
    .category_sort_filter_left {
        float: right;
    }
}
    
@media (max-width: 767px) {
    .listin5 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .listin2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .listin3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .listin5crsstarted {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .category_sort_filter_left {
        padding-top: 20px;
    }   
}

.NewCourseList .ry-next {
    position: absolute;
    transform: translate(0);
    z-index: 999;
    top: 50%;
    right: 0px;
    background: transparent;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border: 0px solid #fff;
}
.NewCourseList .ry-next:before {
    content: "\f054";
    font-family: "font awesome 5 pro";
    line-height: 1;
    font-size: 20px;
    opacity: 1;
    color: #7a3694;
    font-weight: 900;
}
.NewCourseList:hover .ry-next:before {
    opacity: 1;
    color: #000;
}

.NewCourseList .ry-prev {
    position: absolute;
    transform: translate(0);
    z-index: 999;
    top: 50%;
    left: 0px;
    background: transparent; 
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border: 0px solid #fff;
}
.NewCourseList .ry-prev:before {
    content: "\f053";
    font-family: "font awesome 5 pro";
    line-height: 1;
    font-size: 20px;
    opacity: 1;
    color: #7a3694;
    font-weight: 900;
}
.NewCourseList:hover .ry-prev:before {
    opacity: 1;
    color: #000;
}
.CategoryChooseFilter .appie-features-content {
    padding-top: 10px;
    margin: 20px 10px;
}
.CategoryChooseFilter .slick-next {
    top: 40% !important;
    background: #7a3694;
    height: 50px !important;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border: 0px solid #fff;
    /* right: 0px !important; */
    width: 30px !important;
}
.CategoryChooseFilter .slick-next:before {
    color: #fff !important;
}

.CategoryChooseFilter .slick-prev {
    top: 40% !important;
    background: #7a3694;
    height: 50px !important;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border: 0px solid #fff;
    /* left: 0px !important; */
    width: 30px !important;
}
.CategoryChooseFilter .slick-prev:before {
    color: #fff !important;
}
@media (max-width: 767px) {
    .CategoryChooseFilter .slick-next {
        top: 35% !important;
        right: -20px !important;
    }
    .CategoryChooseFilter .slick-prev {
        top: 35% !important;
        left: -20px !important;
    }
}