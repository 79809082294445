.imagePopUP {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 999997;
    bottom: 100px;
    left: 0px;
    right: 0px;
    object-fit: contain;
    transition: transform 0.3s ease;
}
.imagePopUPSlider {
    width: 100%;
    position: fixed;
    z-index: 999999;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0 30px;
    background: grey;
}
.imagePopUPSlider img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}
.imagePopUPBox {
    background: #000;
    position: fixed;
    top: 0px;
    z-index: 9999966;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
@media (max-width: 767px) {
    .imagePopUPSlider img {
        height: 50px;
    }
}
.imagePopUPClose {
    position: fixed;
    z-index: 999999;
    color: #fff;
    right: 10px;
    cursor: pointer;
}
.imagePopUPIndex {
    position: fixed;
    z-index: 999999;
    color: #fff;
    left: 10px;
}
@media (min-width: 769px) {
    .ReactGridGallery_tile {
        width: 20%;
        margin: 0px !IMPORTANT;
        border: 1px solid #000;
    }
    .ReactGridGallery_tile img {
        width: 100%;
        height: auto;
        object-fit: cover; 
    }
}
@media (max-width: 768px) {
    .ReactGridGallery_tile {
        width: 50%;
        margin: 0px !IMPORTANT;
        border: 1px solid #000;
    }
}